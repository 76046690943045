import * as React from "react";
import { useState, useEffect } from "react";
import { Column, ColumnAlignType } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useTranslation } from "react-i18next";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import _ from "lodash";
import moment from "moment";
import { env } from "../../environment";
import "./partner-manager.scss";
import { CMsPageManagerAPI, PartnerManagerAPI } from "../../services";
import { removeEmpty } from "../../utils/utils";
import { formatDateTimeV2 } from "../../utils/logic";
import PreAddPartnerForm from "./components/pre-add-partner-form";
import { CMS_STATUS_CODE, PARTNER_TYPES, PERMISSIONS_V2, STATUS_CODE, STATUS_LIST } from "../../components_v2/utils/utils";
import Layout from "../../components_v2/common/layout/layout";
import WISearchField from "../../components_v2/common/search/wi-search-field";
import WIPaginator from "../../components_v2/common/wi-paginator/wi-paginator";
import WiStatus from "../../components_v2/common/wi-status/wi-status";
import MenuDot from "../../components_v2/common/menu-dot/menu-dot";
import { useLayoutV2 } from "../../context/LayoutProvider";
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { ReactComponent as FilterSvg } from '../../assets/images/icons/filter-cog.svg';
import { updatePage, updatePartner } from "../../components/partner-manager/partner-business";
import Logo from '../../assets/images/icons/logo.svg';
import useAuth from "../../context/useAuth";
import PartnerFilter from "./components/partner-filter/partner-filter";
import { useSearchParams } from "react-router-dom";
import { getObjectWithValues, getPayloadConditions } from "./partner.util";
import WiTooltip from "../../components_v2/common/wi-tooltip/wi-tooltip";

const PartnerManagerV2 = () => {
  const { getLanguage, setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();
  const { t } = useTranslation('language', { keyPrefix: 'partner_manager' });
  const { auth } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchField = searchParams?.get('searchText') || '';
  const filterStatus = (searchParams?.get('filterStatus') || STATUS_LIST.map((c) => c.code).join(',')).split(',');
  const filterTypes = searchParams?.get('filterTypes') ? searchParams?.get('filterTypes')?.split(',') || [] : [];
  const filterCategories = searchParams?.get('filterCategories') ? searchParams?.get('filterCategories')?.split(',') || [] : [];
  
  const { t: globalTrans } = useTranslation('language');
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const [isLoading, setIsLoading] = useState(false);
  const [partners, setPartners] = useState({
    data: [],
    totalPage: 0,
  });
  const [page, setPage] = useState(0);
  const [partner, setPartner] = useState<any>(null);
  const [search, setSearch] = useState<any>(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(25);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isShowSidebarFilter, setIsShowSidebarFilter] = useState(false);
  const [filterValues, setFilterValues] = useState<any>({
    pageNo: page,
    range: rows,
    where: {
      searchText: searchField,
      status: filterStatus,
      type: filterTypes,
      category: filterCategories,
    },
    order: []
  });

  const permissions = {
    canCreatePartner: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_CREATE),
    canUpdatePartner: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_UPDATE),
    canViewCMSPAGE: auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_VIEW),
    canUpdateCMSPAGE: auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_UPDATE),
    canViewPartner: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_VIEW)
  };

  const tableColumns = [
    {
      field: 'partner_logo', header: `${t('txt_name')}`, width: "140px", align: 'left',
      bodyTemplate: (rowData: any) => {
        const logoPartner = rowData.type !== PARTNER_TYPES.FUNDRAISER ? `${env.PUBLIC_UPLOAD_URL}/${rowData.partner_logo}?u=${rowData.updated_at}` : Logo;
        return <div className="logo-image"><img alt={rowData.name} src={logoPartner} /></div>
      }
    },
    {
      field: 'name', header: '', width: "calc(100% - 1100px)", align: 'left',
      bodyTemplate: (rowData: any) => {
        return <div className="cell-info">
          <div className="cell-main-info pb-12">
            {rowData.name}
          </div>
          <div className="cell-sub-info">
            {globalTrans(`partner_type.txt_${rowData.type?.replace('-', '_')}`)}
          </div>
        </div>
      }
    },
    {
      field: 'status', header: `${t('txt_status')}`, width: "250px", align: 'center',
      bodyTemplate: (rowData: any) => {
        const statusItem = STATUS_LIST.find((item: any) => item.code === rowData.status);
        return <WiStatus item={statusItem}></WiStatus>
      }
    },
    // {
    //   field: 'category', header: `${t('txt_category')}`, width: "250px", align: 'center',
    //   bodyTemplate: (rowData: any) => {
    //     return rowData.category ? `${globalTrans(`partner_category.txt_${rowData.category?.replace('-', '_')}`)}`: '';
    //   }
    // },
    {
      field: "has_accepted_policy",
      header: t("txt_agreement"),
      width: "120px",
      align: "center",
      bodyTemplate: (rowData: any) => {
        return (
          !!rowData.has_accepted_policy ?
            <Button className={`no-mouse-events wi-primary-button-icon-v2`}>
              <span className="fa-solid fa-check"/>
            </Button> : <></>
        )
      }
    },
    {
      field: 'created_at', header: `${t('txt_created')}`, width: "260px", align: 'center',
      bodyTemplate: (rowData: any) => {
        const user = rowData?.created_user;
        const displayName = user ? user.display_name ? user.display_name : user.first_name + ' ' + user.last_name : 'System Admin';
        const bgColor = user && user?.custom_config?.avartar_color ? user?.custom_config?.avartar_color : null;     
        const namePlaceholder = user ? `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}` :  t('txt_sa');
          
        return <div className="cell-info" >
          <div className="cell-main-info pb-12">{formatDateTimeV2(rowData.created_at, getLanguage())}</div>
          <div className="cell-sub-info d-flex justify-content-center">
          <WiTooltip content={displayName} direction="bottom">
            <div className={`user-avatar user-avatar-create-${rowData?.uuid}`}>
              {
                user?.avatar
                  ? <img className="m-0 avatar" src={`${env.PUBLIC_UPLOAD_URL}/${user?.avatar}`} alt="User" loading="lazy" />
                  : <div className="user-avatar-default text-capitalize" style={{ background: bgColor }}>{namePlaceholder}</div>
              }
            </div>
          </WiTooltip>
          </div>
        </div>
      },
    },
    {
      field: 'updated_at', header: `${t('txt_updated')}`, width: "260px", align: 'center',
      bodyTemplate: (rowData: any) => {
        const user = rowData?.updated_user;
        const displayName = user ? user.display_name ? user.display_name : user.first_name + ' ' + user.last_name : 'System Admin';
        const bgColor = user && user?.custom_config?.avartar_color ? user?.custom_config?.avartar_color : null;     
        const namePlaceholder = user ? `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}` :  t('txt_sa');

        return <div className="cell-info">
          <div className="cell-main-info pb-12">{formatDateTimeV2(rowData.updated_at, getLanguage())}</div>
          <div className="cell-sub-info d-flex justify-content-center">
            <WiTooltip content={displayName} direction="bottom">
              <div className={`user-avatar user-avatar-update-${rowData?.uuid}`}>
                {
                  user?.avatar
                    ? <img className="m-0 avatar" src={`${env.PUBLIC_UPLOAD_URL}/${user?.avatar}`} alt="User" loading="lazy" />
                    : <div className="user-avatar-default text-capitalize" style={{ background: bgColor }}>{namePlaceholder}</div>
                }
              </div>
            </WiTooltip>
          </div>
        </div>
      },
    }
  ];

  const dynamicColumns = tableColumns.map((props) => {
    return <Column
      key={props.field}
      style={{ width: props.width }}
      body={props.bodyTemplate}
      field={props.field}
      header={props.header}
      className={props.field}
      align={props.align as ColumnAlignType}
    />;
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_open'),
        icon: "wi-open",
        url: `/partners/${rowData?.uuid}`,
        hidden: !permissions.canViewPartner || rowData?.type === PARTNER_TYPES.FUNDRAISER,
      },
      // {
      //   label: t('txt_edit'),
      //   icon: "wi-edit",
      //   hidden: !permissions.canUpdatePartner || rowData?.type !== PARTNER_TYPES.FUNDRAISER,
      //   command: () => {
      //     setPartner(rowData);
      //     setIsShowSidebar(true);
      //   }
      // },
      // {
      //   label: t('txt_change_category'),
      //   icon: "wi-list",
      //   command: () => {
      //     setPartner(rowData);
      //     setIsShowSidebar(true);
      //   }
      // },
      {
        label: t('txt_active'),
        icon: "wi-active",
        hidden: !permissions.canUpdatePartner || rowData?.status === STATUS_CODE.ACTIVE,
        command: () => changePartnerStatus(rowData, STATUS_CODE.ACTIVE)
      },
      {
        label: t('txt_inactive'),
        icon: "wi-inactive",
        hidden: !permissions.canUpdatePartner || rowData?.status === STATUS_CODE.INACTIVE,
        command: () => changePartnerStatus(rowData, STATUS_CODE.INACTIVE)
      },
      {
        label: t('txt_archive'),
        icon: "wi-archive",
        hidden: !permissions.canUpdatePartner || rowData?.status === STATUS_CODE.ARCHIVED,
        command: () => changePartnerStatus(rowData, STATUS_CODE.ARCHIVED)
      },
      {
        label: t('txt_edit_cms_page'),
        icon: "wi-edit-paint",
        hidden: !permissions.canUpdateCMSPAGE || rowData?.type === PARTNER_TYPES.FUNDRAISER || !rowData.cmsPage,
        url: `/cms-pages/${rowData.cmsPage?.uuid}`
      },
      {
        label: t('txt_open_cms_page'),
        icon: "wi-open-doc",
        hidden: true || !permissions.canViewCMSPAGE || rowData?.type === PARTNER_TYPES.FUNDRAISER,
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  }

  const onSearchHistory = () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return;
    }
    getPartnersData();
  }

  const getAllData = async () => {
    await Promise.all([
      getPartnersData()
    ]);
  }

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === "number") {
      setFilterValues({
        ...filterValues,
        pageNo: page,
        range: rows,
      });
      setSearchParams({
        pageNumber: (page + 1).toString(),
        noRows: rows.toString(),
      }, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const changePartnerStatus = async (partnerValues: any, newStatus: any) => {
    try {
      if (partnerValues && newStatus) {
        setLoadingProgress(errorTrans('txt_loading'));
        let partnerPageId = partnerValues.cmsPage?.uuid;
        const partnerData = _.cloneDeep(partnerValues);
        partnerData.status = newStatus;

        await updatePartner(partnerValues.uuid || '', partnerData);
        if (partnerPageId && partnerValues.status === STATUS_CODE.NEW && newStatus === STATUS_CODE.ACTIVE) {
          const pageData = _.cloneDeep(partnerValues.cmsPage);
          pageData.status = CMS_STATUS_CODE.PUBLISHED;
          pageData['custom_config'] = pageData['custom_config'] || {};
          pageData['custom_config']['published_info'] = pageData['custom_config']['published_info'] || [];
          pageData['custom_config']['published_info'].push({ time: moment().toISOString() })
          partnerPageId = await updatePage(partnerPageId, pageData); // API will publish page if page status is Published
          await PartnerManagerAPI.updatePartners(partnerValues.uuid, {}); // refresh partner master page
        }

        setPartner(partnerData);
        onSearchHistory();
        setSuccessProgress(errorTrans('txt_update_partner_success'));
      }
    } catch (error: any) {
      let errorTxt = 'txt_failed_update_partner';
      if (error.message && error.message.includes('txt_')) {
        errorTxt = error.message;
      }
      setErrorProgress(errorTrans(errorTxt));
    } finally {
    }
  };

  const getPartnersData = async () => {
    try {
      setIsLoading(true);
      const payload = getPayloadConditions(filterValues.where);
      const data = removeEmpty({ pageNo: page, range: rows, ...payload });

      const res = await PartnerManagerAPI.getPartners(data);
      if (!res || res.status !== 200) {
        throw new Error('txt_unknown_load_data_fail');
      }

      const partners = res.data.records || [];
      let pageRes = await CMsPageManagerAPI.searchAllPagesWithPagination(
        { conditions: { parentIds: partners.map((p: any) => p.uuid) } },
        { pageNo: 0, range: rows }
      );

      if (!pageRes || pageRes.status !== 200) {
        throw new Error('txt_unknown_load_data_fail');
      }

      const cmsPages = pageRes.data.records || [];
      partners.forEach((p: any) => {
        p.cmsPage = cmsPages.find((c: any) => c.parent_id === p.uuid);
      });

      setPartners({
        data: partners,
        totalPage: res?.data?.total || 0
      });
    } catch (error: any) {
      setPartners({ data: [], totalPage: 0 });
      setErrorProgress(errorTrans(error.message));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const searchParams: any = {
      pageNumber: (page + 1).toString(),
      noRows: rows.toString(),
      searchText: filterValues.where?.searchText,
      filterStatus: filterValues.where?.status?.join(',') || '',
      filterTypes: filterValues.where?.type?.join(',') || '',
      filterCategories: filterValues.where?.category?.join(',') || '',
    };

    setSearchParams(_.omitBy(searchParams, (p) => !p), { replace: true });
    getPartnersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  const onFilterChange = (values: any, fields: any) => {
    let where_conditions = getObjectWithValues(filterValues.where);

    fields.forEach((field: any) => {
      const data = values?.get(field);
      where_conditions[field] = data;
    });

    setFilterValues({
      ...filterValues,
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order
    });
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    p.where["status"] = [...STATUS_LIST.map((c) => c.code)];
    p.where.searchText = "";
    setFilterValues(p);
  };

  const onSearch = (searchText: string) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    where_conditions["searchText"] = searchText || null;
    
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order,
    });
    if (page !== 0) {
      setPage(0);
      setFirst(0);
    }
  };

  return (
    <Layout title={t('txt_title')} className="partner-manager">
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={"pi pi-search"}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={() => onSearch(search)}
              globalValue={search}
            />
            <div className="filter-btn">
              <div className="filter-btn-content p-8 ml-16" onClick={() => setIsShowSidebarFilter(true)}>
                <FilterSvg />
              </div>
            </div>
          </div>
          <Button
            className="wi-primary-button-v2"
            label={t('txt_btn_add')}
            hidden={!permissions.canCreatePartner}
            onClick={(e: any) => {
              setPartner(null);
              setIsShowSidebar(true);
            }}>
            <PlusSvg className="icon-svg" />
          </Button>
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={partners.data}
          emptyMessage={t('txt_no_records')}
          responsiveLayout="scroll"
          scrollable
          scrollHeight="calc(100vh - 214px)"
          scrollDirection="both"
        >
          {dynamicColumns}
          <Column
            frozen
            alignFrozen="right"
            style={{ width: '64px' }}
            body={buildMenu}>
          </Column>
        </DataTable>
        <WIPaginator
          first={first}
          rows={rows}
          totalRecords={partners.totalPage}
          onPageChange={onBasicPageChange}
        />
      </div>
      <Sidebar
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        visible={isShowSidebar}
        onHide={() => setIsShowSidebar(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6 className="m-0">{partner ? (partner?.type !== PARTNER_TYPES.FUNDRAISER ? t('txt_change_category') : t('txt_edit')) : t('txt_btn_add')}</h6>
          </div>
          <PreAddPartnerForm
            onHide={() => setIsShowSidebar(false)}
            refreshData={() => onSearchHistory()}
            partner={partner}
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowSidebarFilter}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        onHide={() => setIsShowSidebarFilter(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_filter')}</h6>
          </div>
          <PartnerFilter
            filterValues={filterValues}
            setSearch={setSearch}
            fields={['status', 'type', 'category']}
            onRemove={onFilterRemove}
            onSubmit={(values: any, fields: any) => onFilterChange(values, fields)}
          />
        </div>
      </Sidebar>
    </Layout>
  );
};

export default PartnerManagerV2;
