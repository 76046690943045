import React, { createContext, useContext, useState } from "react";
import { TYPE_LOADING } from "../components_v2/utils/utils";
import { useTranslation } from "react-i18next";

const LayoutContext = createContext<any>({});

const LayoutProvider = ({ children }: any) => {
  const { i18n } = useTranslation();
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [progress, setProgress] = useState({
    display: false,
    loading: false,
    type: TYPE_LOADING.LOADING,
    message: ''
  });
  const [popupSaveChange, setPopupSaveChange] = useState({
    visible: false,
    onSaveChange: () => {},
    onCancel: () => {}
  });

  const turnOffProgress = () => {
    setProgress({
      display: false,
      loading: false,
      type: TYPE_LOADING.LOADING,
      message: ''
    });
  } 

  const setLoadingProgress = (text: string) => {
    setProgress({
      display: true,
      loading: true,
      type: TYPE_LOADING.LOADING,
      message: text
    });
  } 

  const setSuccessProgress = (text: string) => {
    setProgress({
      display: true,
      loading: true,
      type: TYPE_LOADING.SUCCESS,
      message: text
    });
  } 

  const setErrorProgress = (text: string) => {
    setProgress({
      display: true,
      loading: true,
      type: TYPE_LOADING.ERROR,
      message: text
    });
  } 

  const actionPopupSaveChange = (actions: any) => {
    setPopupSaveChange({
      ...popupSaveChange,
      ...actions
    });
  }

  const setGlobalLanguage = (languageCode = 'en') => {
    i18n.changeLanguage(languageCode || 'en');
  }

  const getLanguage = () => {
    return i18n.language || 'en';
  }

  return (
    <LayoutContext.Provider value={{
      progress,
      setProgress,
      setLoadingProgress,
      setErrorProgress,
      setSuccessProgress,
      turnOffProgress,
      setGlobalLanguage,
      isContentLoading, 
      setIsContentLoading,
      getLanguage,
      popupSaveChange,
      setPopupSaveChange,
      actionPopupSaveChange
    }}>
        {children}
    </LayoutContext.Provider>
  )
}

const useLayoutV2 = () => {
  return useContext(LayoutContext);
}

export { LayoutProvider, useLayoutV2 }; 