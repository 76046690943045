import * as React from "react";
import "./wi-toggle.scss";

const WiToggleButton = (props: any) => {
    const { name, label, className, checked, onChange, disabled } = props;
  
    return (
      <div className={`wi-toggle-button pt-10 pb-10 pl-24 pr-4 ${className || ''}`}>
        <span className="label-toggle pr-16"> {label} </span>
        <label className="switch">
          <input name={name || ''} type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
          <span className={`slider round ${disabled && 'dis-slider'}`} ></span>
        </label>
      </div>
    );
  }

export default WiToggleButton;