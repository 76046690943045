import _ from "lodash";
import { generatePageData } from "../../utils/commonGeneratePageData";
import { LanguageItem } from "../../utils/commonType";
import * as Yup from 'yup';
import { CMS_STATUS_CODE, PARTNER_TYPES, STATUS_CODE, BLOCK_CTA_IDS } from "../../components_v2/utils/utils";
import { getPageTypeByCode, getContentById } from "../pages/builder-page.common";
import { PAGE_CODE } from "../pages/utils";
import { CMsPageManagerAPI, PartnerManagerAPI } from "../../services";
import moment from "moment";

export interface PartnerType {
    name: string;
    code: string;
    status: string;
    partner_logo: string;
    headline_image: string;
    mobile_headline_image: string;
    description: LanguageItem;
    fact: LanguageItem;
    slogan: LanguageItem;
    type: string;
    category: string;
    generate_page: boolean;
    since_date: Date;
}

export const buildPartnerSchema = (errorTrans: any) => Yup.object().shape({
    name: Yup.string()
        .required(errorTrans('txt_required')),
    partner_logo: Yup.string().when("type", {
        is: (type: string) => type !== PARTNER_TYPES.FUNDRAISER,
        then: Yup.string().required(errorTrans('txt_required')),
    }),
    type: Yup.string()
        .required(errorTrans('txt_required')),
    category: Yup.string().required(errorTrans('txt_required')),
});

export const initializePartnersValues = (partner: any, code = '', type = '', category = ''): PartnerType => {
    return {
        name: partner ? `${partner.name}` : "",
        code: partner ? `${partner.code}` : (code || ""),
        status: partner ? `${partner.status}` : STATUS_CODE.NEW,
        type: partner ? `${partner.type}` : (type || PARTNER_TYPES.PARTNER),
        category: partner ? `${partner.category}` : (category || ''),
        generate_page: partner?.generate_page || false,
        partner_logo: partner ? `${partner.partner_logo ?? ""}` : "",
        headline_image: partner ? `${partner.headline_image ?? ""}` : "",
        mobile_headline_image: partner ? `${partner.mobile_headline_image ?? ""}` : "",
        description: partner && partner.description ? { de: partner.description.de, en: partner.description.en } : { de: '', en: '' },
        fact: partner && partner.fact ? { de: partner.fact.de, en: partner.fact.en } : { de: '', en: '' },
        slogan: partner && partner.slogan ? { de: partner.slogan.de, en: partner.slogan.en } : { de: '', en: '' },
        since_date: moment(partner?.since_date || new Date()).toDate()
    }
};

export const generatePartnerPageData = async (partnerId: any, partnerData: any, parentPages: any, cmsPage: any, partnerPageType: any, allBlocks: any, footers: any, headers: any, isPublished = false) => {
    let mappings: any = {
        'image-content-header': {
            'headline.de': partnerData.name,
            'subHeadline.de': partnerData.slogan?.de,
            'picture': partnerData.headline_image,
            'mobile_picture': partnerData.mobile_headline_image
        },
        'partner-box': {
            'partner.partner_logo': partnerData.partner_logo,
            'partner.description.de': partnerData.fact?.de,
        },
        'paragraph-with-title': {
            'headline.de': '',
            'paragraph.de': partnerData.description?.de,
            'hide_headline': true,
        }
    };

    return await generatePageData(partnerData, parentPages, cmsPage, partnerPageType, mappings, allBlocks, footers, headers, partnerId, partnerData.headline_image, isPublished);
}

export const buildPartnerPageData = async (partnerId: any, partner: any, cmsPage: any, masterData: any, isPublished = false) => {
    const partnerPageType = getPageTypeByCode(masterData.cmsPageTypes, PAGE_CODE.Partner);
    return await generatePartnerPageData(partnerId, partner, masterData.cmsMasterPages, cmsPage, partnerPageType, masterData.ctaBlocks, masterData.footers, masterData.headers, isPublished);
}

export const isFundraiser = (type: any) => type === PARTNER_TYPES.FUNDRAISER;

export const syncDataWithPartnerPage = async (partnerData: any, cmsPartnerPageId: string, cmsMasterData: any) => {
    if (!!isFundraiser(partnerData.type)) {
        return null;
    }

    const isCMSPageExisted = !!cmsPartnerPageId;
    if (isCMSPageExisted) {
        const content = await buildContentCmsPage(cmsPartnerPageId, partnerData);
        await CMsPageManagerAPI.update(cmsPartnerPageId, content);
        await updatePage(cmsPartnerPageId, { status: CMS_STATUS_CODE.PUBLISHED });
        return cmsPartnerPageId;
    } else { // Only happen when creating new page
        let pageData = await buildPartnerPageData(partnerData.uuid, partnerData, { uuid: cmsPartnerPageId }, cmsMasterData, true);
        let newCmsPage = await createAndPublishPage({...pageData });
        return newCmsPage?.uuid;
    }
};

export const syncPartnerData = async (id: any, partnerValues: any) => {
    const partnersRes = await PartnerManagerAPI.getAllPartners(false);
    const partners = partnersRes?.data?.records || [];
    const isDuplicatedCode = partners.some((p: any) => p.uuid !== id && p.code?.toUpperCase() === partnerValues.code?.toUpperCase());
    if (isDuplicatedCode) {
      throw new Error('txt_failed_partner_duplicated_code');
    }

    if (id) {
      return await updatePartner(id || '', partnerValues);
    }
    return await createPartner(partnerValues);
};

export const getPartnerPage = async (uuid: string) => {
    const response = await CMsPageManagerAPI.getCMSPageDetail(uuid);

    if (response && response.status === 200) {
        return response.data.record;
    } else {
        throw new Error('txt_failed_update_partner');
    }
}

export const createDraftPage = async (data: any) => {
    const res = await CMsPageManagerAPI.createPage(data);
    if (res && res.status === 200) {
        return res.data.result;
    } else {
        throw new Error('txt_failed_generate_partner');
    }
}

export const updatePartner = async (uuid: string, data: any) => {
    const response = await PartnerManagerAPI.updatePartners(uuid, data);

    if (response && response.status === 200) {
        return response.data.result;
    } else {
        throw new Error('txt_failed_update_partner');
    }
}

export const createPartner = async (data: any) => {
    const response = await PartnerManagerAPI.generatePartners(data);

    if (response && response.status === 200) {
        return response.data.result;
    } else {
        throw new Error('txt_failed_generate_partner');
    }
}

export const createAndPublishPage = async (data: any) => {
    const res = await CMsPageManagerAPI.createAndPublishPage(data);
    if (res && res.status === 200) {
        return res.data.result;
    } else {
        throw new Error('txt_failed_generate_partner');
    }
}

export const updatePage = async (uuid: string, data: any) => {
    const res = await CMsPageManagerAPI.updatePage(uuid, data);

    if (res && res.status === 200) {
        return res.data.result;
    } else {
        throw new Error('txt_failed_update_partner');
    }
};

export const fetchPartnerData = async (uuid: string) => {
    if (uuid) {
        const res = await PartnerManagerAPI.getPartner(uuid);
        if (res && res.status === 200) {
            return res?.data?.record;
        }
        throw new Error('txt_not_found');
    }
    return null;
};

export const buildContentCmsPage = async (cms_page_id: any, partner_data: any) => {
    const cmsContent = await getContentById(cms_page_id);
    const cmsPageConfiguration = partner_data.cms_page_configuration;
    let mappings: any = {};
    mappings[BLOCK_CTA_IDS.IMAGE_CONTENT_HEADER] = {
        'headline.de': partner_data.name,
        'subHeadline.de': partner_data.slogan?.de,
        'picture': partner_data.headline_image,
        'mobile_picture': partner_data.mobile_headline_image
    };
    mappings[BLOCK_CTA_IDS.PARTNER_BOX] = {
        'partner.partner_logo': partner_data.partner_logo,
        'partner.description.de': partner_data.fact?.de,
        'desktop_mode': !cmsPageConfiguration?.is_show_facts,
        'tablet_mode': !cmsPageConfiguration?.is_show_facts,
        'mobile_mode': !cmsPageConfiguration?.is_show_facts,
    };
    mappings[BLOCK_CTA_IDS.PARAGRAPH_WITH_TITLE] = {
        'headline.de': '',
        'paragraph.de': partner_data.description?.de,
        'hide_headline': true,
        'hide_paragraph': !cmsPageConfiguration?.is_show_description,
        'desktop_mode': !cmsPageConfiguration?.is_show_description,
        'tablet_mode': !cmsPageConfiguration?.is_show_description,
        'mobile_mode': !cmsPageConfiguration?.is_show_description,
    };

    Object.keys(mappings).map((id: any) => {
        const block = cmsContent.find((f: any) => f.is_disabled === true && f.cta_block_id === id);
        if (block) {
            Object.keys(mappings[id]).map((path: any) => {
                _.update(block.parameters, path, (u) => mappings[id][path]);
            });
        }
    });

    return cmsContent;
}

export const checkExistPage = async (page_id: any) => {
    try {
        const res = await CMsPageManagerAPI.fetchHtmlPage(page_id);
        return res && res.status === 200;
    } catch (error) {
    }
    return false;
}
