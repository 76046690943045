import * as React from "react";
import "./wi-tooltip.scss";
import { useState } from "react";

const WiTooltip = (props: any) => {
    const { delay, children, content, direction } = props;

    let timeout:any = null;
    const [active, setActive] = useState(false);
  
    const showTip = () => {
      timeout = setTimeout(() => {
        setActive(true);
      }, delay || 100);
    };
  
    const hideTip = () => {
      clearInterval(timeout);
      setActive(false);
    };
  
    return (
      <>
        <div
          className="tooltip-container-v2"
          onMouseEnter={showTip}
          onMouseLeave={hideTip}
          >
          {children}
          {active && (
            <div className={`tooltip-v2 ${direction || "top"}`}>
              {content}
            </div>
          )}
        </div>
      </>
    );
  }

export default WiTooltip;