import UsersManager from "../pages/users-manager/users-manager";
import PageComponent from '../components/common/page';
import React from "react";
import DonationManager from "../components/donation-manager/donation-manager";
import RegionManager from "../components/region/region-manager/region-manager";
import UserDetails, { ProfileDetails } from "../pages/user-detail/user-detail";
import DonationDetail from "../components/donation-manager/components/donation-detail/donation-detail";
import ImportPage from "../components/import-page/import-page";
import ImportDetail from "../components/import-detail/import-detail";
import EditDonationDetail from "../components/donation-manager/components/edit-donation-detail/edit-donation-detail";
import EditDonationDetailV2 from "../components/donation-manager/components/edit-donation-detail/edit-donation-detail-v2";
import SubscriptionsManager from "../components/subscription/subscription-component";
import RecurringYearlyManager from "../components/recurring-yearly-manager/recurring-yearly-manager";
import CreateRegionManager from "../components/region/components/create-region-component";
import DonorManager from "../components/donor-manager/donor-manager";
import LandManagerComponent from "../components/land-manager/land-manager";
import CreateLandManager from "../components/land-manager/components/create-land-manager";
import CreateCampaignManager from "../components/campaign/components/create-campaign-component";
import CampaignManager from "../components/campaign/campaign-manager/campaign-manager";
import PartnerManagerV2 from "../pages/partner-manager";
import PartnerDetails from "../components/partner-manager/components/partner-details";
import CreateCouponManager from "../components/coupon-manager/components/create-coupon-manager";
import CodeManagerV2 from "../pages/code-manager/code-manager-v2";
import CouponDetailPage from "../components/coupon-detail/coupon-detail";
import PageManager from "../components/pages/page-manager/page-manager";
import BuilderPage from '../components/pages/builder-page';
import PageDetails from '../components/pages/page-details';
import MediaManager from "../components/global-contents/media-manager/media-manager";
import RunnersDetails from "../components/runners-details/runners-details";
import TeamsDetails from "../components/teams-details/teams-details";
import WildernessRunDetails from "../components/wilderness-run-details/wilderness-run-details";
import WildernessRunManager from "../components/wilderness-run-manager/wilderness-run-manager";
import ReceiptManager from "../components/receipts/receipt-manager";
import GoalManager from "../components/goal-manager/goal-manager";
import CouponManager from "../components/campaign/coupon-manager/coupon-manager";
import FAQsManager from "../components/global-contents/faqs/faqs-manager";
import HeadersManager from "../components/global-contents/header-manager/headers-manager";
import QuoteManager from "../components/global-contents/quote-manager/quote-manager";
import CreateContactManager from "../components/global-contents/contact/components/create-contact-manager";
import ContactCardManager from "../components/global-contents/contact/contact-manager";
import GlobalBlockManager from "../components/global-contents/global-block/global-block";
import CMSPagePreview from "../components/pages/cms-page-preview/preview-page";
import AcceptTermPage from "../components/login/accept-term/accept-term";
import FootersManager from "../components/global-contents/footer-manager/footers-manager";
import DonationExamplesManager from "../components/global-contents/donation-example/donation-examples-manager";
import MatchFundingManager from "../pages/match-funding-manager/match-funding-manager";

export const AdminRoutes = [
    {
        path: "/users", 
        element: <PageComponent component={UsersManager} title="Users" />
    },
    {
        path: "/users/:id", 
        element: <PageComponent component={UserDetails} title="User Details" />
    },
    {
        path: "/donors", 
        element: <PageComponent component={DonorManager} title="Donors" />
    },
    {
        path: "/donations", 
        element: <PageComponent component={DonationManager} title="Donations" />
    },
    {
        path: "/donations/:id", 
        element: <PageComponent component={DonationDetail} title="Donation Details" />
    },
    {
        path: "/edit-donations/:id", 
        element: <PageComponent component={EditDonationDetailV2} title="Donations" />
    },
    {
        path: "/edit-donation/:id", 
        element: <PageComponent component={EditDonationDetail} title="Donations" />
    },
    {
        path: "/import", 
        element: <PageComponent component={ImportPage} title="Import Donations" />
    },
    {
        path: "/import-detail/:id", 
        element: <PageComponent component={ImportDetail} title="Import Details" />
    },
    {
        path: "/subscriptions", 
        element: <PageComponent component={SubscriptionsManager} title="Subscriptions" />
    },
    {
        path: "/subscriptions/geo", 
        element: <PageComponent component={RecurringYearlyManager} title="Subscriptions" />
    },
    {
        path: "/regions", 
        element: <PageComponent component={RegionManager} title="Regions" />
    },
    {
        path: "/regions/create", 
        element: <PageComponent component={CreateRegionManager} title="Create Region" />
    },
    {
        path: "/regions/:id", 
        element: <PageComponent component={CreateRegionManager} title="Region Details" />
    },
    {
        path: "/areas", 
        element: <PageComponent component={LandManagerComponent} title="Areas" />
    },
    {
        path: "/areas/create", 
        element: <PageComponent component={CreateLandManager} title="Create Area" />
    },
    {
        path: "/areas/:id", 
        element: <PageComponent component={CreateLandManager} title="Area Details" />
    },
    {
        path: "/partners", 
        element: <PageComponent component={PartnerManagerV2} title="Partners" />
    },
    {
        path: "/codes", 
        element: <PageComponent component={CodeManagerV2} title="Code Batches" />
    },
    {
        path: "/codes/create", 
        element: <PageComponent component={CreateCouponManager} title="Create Code Batch" />
    },
    {
        path: "/codes/:id", 
        element: <PageComponent component={CouponDetailPage} title="Code Batch Details" />
    },
    {
        path: "/wilderness-runs", 
        element: <PageComponent component={WildernessRunManager} title="Wilderness Runs" />
    },
    {
        path: "/wilderness-runs/:id", 
        element: <PageComponent component={WildernessRunDetails} title="Wilderness Run Details" />
    },
    {
        path: "/wilderness-runs/:runId/teams/:id", 
        element: <PageComponent component={TeamsDetails} title="Wilderness Run Team Details" />
    },
    {
        path: "/wilderness-runs/:runId/teams/:teamId/runners/:id", 
        element: <PageComponent component={RunnersDetails} title="Wilderness Runner Details" />
    },
    {
        path: "/utilities/manual-receipts", 
        element: <PageComponent component={ReceiptManager} title="Manual Receipts" />
    },
    {
        path: "/utilities/targets", 
        element: <PageComponent component={GoalManager} title="Targets" />
    },
    {
        path: "/global-contents/faqs", 
        element: <PageComponent component={FAQsManager} title="FAQs" />
    },
    {
        path: "/global-contents/cms-headers", 
        element: <PageComponent component={HeadersManager} title="CMS Headers" />
    },
    {
        path: "/global-contents/cms-footers", 
        element: <PageComponent component={FootersManager} title="CMS Footers" />
    },
    {
        path: "/global-contents/cms-quotes", 
        element: <PageComponent component={QuoteManager} title="CMS Quotes" />
    },
    {
        path: "/global-contents/donation-examples", 
        element: <PageComponent component={DonationExamplesManager} title="Donation Examples" />
    },
    {
        path: "/global-contents/people", 
        element: <PageComponent component={ContactCardManager} title="People" />
    },
    {
        path: "/global-contents/people/create", 
        element: <PageComponent component={CreateContactManager} title="Create Person" />
    },
    {
        path: "/global-contents/people/:id", 
        element: <PageComponent component={CreateContactManager} title="Person Details" />
    },
    {
        path: "/global-contents/cms-global-blocks", 
        element: <PageComponent component={GlobalBlockManager} title="CMS Global Blocks" />
    },
];

export const CommonRoutes = [
    {
        path: "/profile/settings", 
        element: <PageComponent component={ProfileDetails} title="User Profile" />
    },
    {
        path: "/campaigns", 
        element: <PageComponent component={CampaignManager} title="Campaigns" />
    },
    {
        path: "/campaigns/create", 
        element: <PageComponent component={CreateCampaignManager} title="Create Campaign" />
    },
    {
        path: "/campaigns/:id", 
        element: <PageComponent component={CreateCampaignManager} title="Campaign Details" />
    },
    {
        path: "/cms-pages", 
        element: <PageComponent component={PageManager} title="CMS Pages" />
    },
    {
        path: "/cms-pages/create", 
        element: <PageComponent component={PageDetails} title="Create CMS Page" />
    },
    {
        path: "/cms-pages/:id", 
        element: <PageComponent component={PageDetails} title="CMS Page Details" />
    },
    {
        path: "/cms-pages/:id/content", 
        element: <PageComponent component={BuilderPage} title="CMS Page Content" />
    },
    {
        path: "/cms-pages/:id/preview", 
        element: <PageComponent component={CMSPagePreview} title="CMS Page Content" />
    },
    {
        path: "/partners/:id", 
        element: <PageComponent component={PartnerDetails} title="Partner Details" />
    },
    {
        path: "/media", 
        element: <PageComponent component={MediaManager} title="Media" />
    },
    {
        path: "/coupons", 
        element: <PageComponent component={CouponManager} title="Coupons" />
    },
    {
        path: "/match-funding", 
        element: <PageComponent component={MatchFundingManager} title="Match Funding" />
    },
];