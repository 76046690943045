import { useRef } from "react";
import React from "react";
import { Formik } from "formik";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useLayoutV2 } from "../../../context/LayoutProvider";
import WiToggleButton from "../../../components_v2/common/wi-toggle/wi-toggle";
import "./edit-partner-profile-page-form.scss";
import { env } from "../../../environment";
import { updatePartner, syncDataWithPartnerPage } from "../partner-business";
import { WIFormObserver } from "../../../components_v2/common/form-fields";
import WithPreventNavigation from "../../common/preventNavigation";
import { useBeforeUnload } from "../../common/useBeforeUnload";
import {
  CMS_STATUS_CODE,
  PERMISSIONS_V2,
} from "../../../components_v2/utils/utils";
import useAuth from "../../../context/useAuth";
import { getUserType } from "../../../utils/logic";
import { UserTypes } from "../../../utils/utils";

const EditPartnerProfilePageForm = (props: any) => {
  const {
    setLoadingProgress,
    setSuccessProgress,
    setErrorProgress,
    actionPopupSaveChange,
  } = useLayoutV2();
  const {
    onHide,
    fetchCallBack,
    partner,
    cmsPartnerPage,
    masterData,
    shouldBlock,
    setShouldBlock,
  } = props;
  const formikRef = useRef(null);
  const { t } = useTranslation("language", {
    keyPrefix: "partner_profile_form",
  });
  const { t: errorTrans } = useTranslation("language", { keyPrefix: "errors" });

  const { auth } = useAuth();
  const isWISystemUser = getUserType(auth) === UserTypes.WIEmployee;
  const isTenantMatched = auth?.tenant_id === partner.uuid && !isWISystemUser;
  const permissions = {
    canUpdatePartner:
      auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_UPDATE) ||
      isTenantMatched,
  };

  const hasSaveDataForm = () => {
    // @ts-ignore: Object is possibly 'null'.
    const { dirty } = formikRef?.current;
    if (formikRef && formikRef.current && dirty) {
      actionPopupSaveChange({
        visible: true,
        onCancel: () => onHide(),
        onSaveChange: () => onSubmit(),
      });
    } else {
      onHide();
    }
  };

  const onSubmit = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      await formikRef.current?.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && isValid) {
        setLoadingProgress(errorTrans("txt_loading"));

        const newPartnerData = {
          ...partner,
          cms_page_configuration: values,
        };

        const partnerData = await updatePartner(partner.uuid, {
          cms_page_configuration: values,
        });

        if(!!cmsPartnerPage?.id){
          await syncDataWithPartnerPage(newPartnerData, cmsPartnerPage?.id, masterData);
        }
        
        if (!!partnerData?.uuid) {
          setSuccessProgress(errorTrans("txt_update_partner_success"));
          fetchCallBack({
            partner: newPartnerData,
            cms_page_id: cmsPartnerPage?.id,
          });
          onHide();
          return;
        }
      }
    } catch (e: any) {}
    setErrorProgress(errorTrans("txt_update_partner_failed"));
  };

  const initialFormValues = () => {
    let initialDonor = {
      is_show_description: partner?.cms_page_configuration?.is_show_description,
      is_show_facts: partner?.cms_page_configuration?.is_show_facts,
    };
    return initialDonor;
  };

  useBeforeUnload((event: any) => {
    if (shouldBlock) {
      event.preventDefault();
    }
  });

  return (
    <>
      <div className="edit-partner-profile-page-form">
        <div className="edit-partner-profile-page-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            onSubmit={(values, { setSubmitting }) => {
              //console.log("values >>>", values);
            }}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              isValid,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleChange,
            }) => (
              <>
                <WIFormObserver setShouldBlock={setShouldBlock} />
                <form onSubmit={handleSubmit}>
                  <div className="row-content">
                    <div className="row">
                      <div className="element-form mb-24">
                        <Button
                          className="wi-secondary-button-v2 h48 flex-1"
                          type="submit"
                          style={{ width: "100%" }}
                          label={`${t("txt_profile_preview")}`}
                          disabled={
                            !cmsPartnerPage?.url ||
                            cmsPartnerPage?.status !== CMS_STATUS_CODE.PUBLISHED
                          }
                          onClick={() => {
                            window.open(
                              `${env.CMS_URL}${cmsPartnerPage?.url}`,
                              "_blank"
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="element-form mb-24">
                        <WiToggleButton
                          name="isShowDescription"
                          className="wi-toggle-edit-partner pr-16 pl-16"
                          label={`${t("txt_description")}`}
                          onChange={(item: any) => {
                            setFieldValue(
                              "is_show_description",
                              item.target.checked,
                              true
                            );
                          }}
                          onBlur={handleBlur}
                          checked={values.is_show_description}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="element-form mb-24">
                        <WiToggleButton
                          name="isShowFacts"
                          className="wi-toggle-edit-partner pr-16 pl-16"
                          label={`${t("txt_facts")}`}
                          onChange={(item: any) => {
                            setFieldValue(
                              "is_show_facts",
                              item.target.checked,
                              true
                            );
                          }}
                          onBlur={handleBlur}
                          checked={values.is_show_facts}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-start gap-24">
                          <Button
                            className="wi-danger-button-v2 h48 flex-1"
                            type="submit"
                            label={`${t("txt_cancel")}`}
                            onClick={() => hasSaveDataForm()}
                          />
                          <Button
                            className="wi-primary-button-v2 h48 flex-1"
                            type="submit"
                            label={`${t("txt_save")}`}
                            disabled={
                              !(isValid && dirty) ||
                              !permissions.canUpdatePartner
                            }
                            onClick={() => {
                              if (partner) {
                                onSubmit();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default WithPreventNavigation(EditPartnerProfilePageForm);
