import React from "react";
import { useFormikContext } from "formik";
import { useEffect } from "react";

const WIFormObserver = ({ setShouldBlock }: any) => {
  const { values, dirty } = useFormikContext();
  useEffect(() => {
    setShouldBlock(dirty);
  }, [values, dirty]);

  return null;
};

export default WIFormObserver;