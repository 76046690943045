import { useRef } from "react";
import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useLayoutV2 } from "../../../context/LayoutProvider";
import { updatePartner } from "../partner-business";
import WIFormInputTextV2 from "../../../components_v2/common/form-fields/wi-form-input";
import WIFormDropdownV2 from "../../../components_v2/common/form-fields/wi-form-dropdown";
import WithPreventNavigation from "../../common/preventNavigation";
import { WIFormObserver } from "../../../components_v2/common/form-fields";
import { useBeforeUnload } from "../../common/useBeforeUnload";
import useAuth from "../../../context/useAuth";
import { getUserType } from "../../../utils/logic";
import { UserTypes } from "../../../utils/utils";
import { PERMISSIONS_V2 } from "../../../components_v2/utils/utils";

const EditContactInfoForm = (props: any) => {
  const { setLoadingProgress, setSuccessProgress, setErrorProgress, actionPopupSaveChange } = useLayoutV2();
  const { onHide, fetchCallBack, partner, countries, shouldBlock, setShouldBlock } = props;

  const formikRef = useRef(null);
  const { t } = useTranslation('language', { keyPrefix: 'partner_contact_form' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });

  const { auth } = useAuth();
  const isWISystemUser = getUserType(auth) === UserTypes.WIEmployee;
  const isTenantMatched = auth?.tenant_id === partner.uuid && !isWISystemUser;
  const permissions = {
    canUpdatePartner: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_UPDATE) || isTenantMatched,
  };

  const hasSaveDataForm = () => {
    // @ts-ignore: Object is possibly 'null'.
    const { dirty } = formikRef?.current;
    if(formikRef && formikRef.current && dirty) {
      actionPopupSaveChange({
        visible: true,
        onCancel: () => onHide(),
        onSaveChange: () => onSubmitUpdatePartner()
      });
    } else {
      onHide();
    }
  }

  const onSubmitUpdatePartner = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      await formikRef.current?.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && isValid) {
        setLoadingProgress(errorTrans('txt_loading'));
        let updateData = {
          ...partner,
          person_email: values.person_email?.trim()?.toLowerCase(),
          person_first_name: values.person_first_name,
          person_last_name:  values.person_last_name,
          person_address:  values.person_address,
          person_additional_address: values.person_additional_address,
          person_post_code:  values.person_post_code,
          person_city: values.person_city,
          person_country: values.person_country,
        };

        let updateRes =  await updatePartner(updateData.uuid || '', updateData);
        if (updateRes) {
          setSuccessProgress(t('txt_update_partner_success'));
          fetchCallBack(updateRes);
          onHide()
          return;
        }
       
        throw new Error('txt_update_partner_failed');

      }
    } catch (e: any) {
      setErrorProgress(errorTrans(e?.message || 'txt_update_partner_failed'));
    }
  };

  const validationSchema = Yup.object().shape({
    person_email: Yup.string()
      .email(errorTrans('txt_email_format'))
  });

  const initialFormValues = () => {
    let initialDonor = {
      person_first_name: partner ? partner.person_first_name : "",
      person_last_name: partner ? partner.person_last_name : "",
      person_email: partner ? partner.person_email : "",
      person_address: partner ? partner.person_address : "",
      person_additional_address: partner ? partner.person_additional_address : "",
      person_post_code: partner ? partner.person_post_code : "",
      person_city: partner ? partner.person_city : "",
      person_country: partner ? partner.person_country : "DE",
    };

    return initialDonor;
  };

  useBeforeUnload((event: any) => {
    if (shouldBlock) {
      event.preventDefault();
    }
  });

  return (
    <>
      <div className="edit-contact-info-form">
        <div className="edit-contact-info-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              //console.log("values >>>", values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleChange,
            }) => (
              <>
                <WIFormObserver setShouldBlock={setShouldBlock} />
                <form onSubmit={handleSubmit}>
                  <div className="row-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form mb-24">
                          <WIFormInputTextV2
                            title={t('txt_first_name')}
                            name="person_first_name"
                            maxLength={40}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="element-form mb-24">
                          <WIFormInputTextV2
                            title={t('txt_last_name')}
                            name="person_last_name"
                            maxLength={40}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="element-form mb-24">
                          <WIFormInputTextV2
                            title={t('txt_email')}
                            name="person_email"
                            maxLength={255}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form mb-24">
                          <WIFormInputTextV2
                            title={t('txt_address')}
                            name="person_address"
                            maxLength={100}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="element-form mb-24">
                          <WIFormInputTextV2
                            title={t('txt_additional_address')}
                            name="person_additional_address"
                            maxLength={100}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form mb-24">
                          <WIFormInputTextV2
                            title={t('txt_postal_code')}
                            name="person_post_code"
                            maxLength={10}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="element-form mb-24">
                          <WIFormInputTextV2
                            title={t('txt_city')}
                            name="person_city"
                            maxLength={85}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="element-form">
                            <WIFormDropdownV2
                              name="person_country"
                              title={t("txt_country")}
                              optionLabel="name"
                              optionValue="ISO2"
                              appendTo="self"
                              hideErrorWhenLoading={true}
                              options={countries || []}
                              filter
                              filterBy="name"
                              filterPlaceholder={t('txt_search')}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-24">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-start gap-24">
                          <Button
                            className="wi-danger-button-v2 h48 flex-1"
                            type="submit"
                            label={`${t('txt_cancel')}`}
                            onClick={() => hasSaveDataForm()}
                          />
                          <Button
                            className="wi-primary-button-v2 h48 flex-1"
                            type="submit"
                            label={`${t('txt_save')}`}
                            disabled={!permissions?.canUpdatePartner}
                            onClick={() => {
                                if(partner) {
                                  onSubmitUpdatePartner()
                                }
                              }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default WithPreventNavigation(EditContactInfoForm);
