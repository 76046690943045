import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import './partner-details.scss';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CMsPageManagerAPI, PartnerManagerAPI } from "../../../services";
import Layout from "../../../components_v2/common/layout/layout";
import { PAGE_CODE } from "../../pages/utils";
import { CMS_STATUS_CODE, CMS_STATUS_LIST, HOMEPAGE_URL, PARTNER_TYPES, PERMISSIONS_V2, STATUS_CODE, STATUS_LIST, WI_TENANT_ID } from "../../../components_v2/utils/utils";
import { fetchPartnerData, getPartnerPage, updatePage, updatePartner,
  syncDataWithPartnerPage, checkExistPage
} from "../partner-business";
import {
  fetchPageTypes, getPageTypeByCode, fetchCMSParentPages,
  fetchCMSFooters, fetchCMSHeaders, fetchAllBlocks
} from "../../pages/builder-page.common";
import { useLayoutV2 } from "../../../context/LayoutProvider";
import moment from "moment";
import { env } from "../../../environment";
import { Sidebar } from "primereact/sidebar";
import PreAddPartnerForm from "../../../pages/partner-manager/components/pre-add-partner-form";
import useAuth from "../../../context/useAuth";
import EditBasicInformation from "./edit-basic-information/edit-basic-information";
import EditContactInforForm from "./edit-contact-info-form";
import MenuDot from "../../../components_v2/common/menu-dot/menu-dot";
import WiStatus from "../../../components_v2/common/wi-status/wi-status";
import { ReactComponent as PersonCircleSvg } from '../../../assets/images/icons/user-circle.svg';
import { ReactComponent as MailSvg } from '../../../assets/images/icons/mail.svg';
import { ReactComponent as MapSvg } from '../../../assets/images/icons/map-pin.svg';
import EditPartnerProfilePageForm from "./edit-partner-profile-page-form";
import CountryService from "../../../services/country-service";
import { getUserType, resetToken } from "../../../utils/logic";
import { UserTypes } from "../../../utils/utils";
import WithdrawConsent from "./withdraw-consent/withdraw-consent";

export const PartnerDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { auth } = useAuth();
  
  const [searchParams] = useSearchParams();
  const partnerCode = searchParams.get('code') || '';
  const partnerType = searchParams.get('type') || '';
  const partnerCategory = searchParams.get('category') || '';

  const { t } = useTranslation('language', { keyPrefix: 'partner_details' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t: globalTrans } = useTranslation('language');

  const { setLoadingProgress, setErrorProgress, setSuccessProgress, setIsContentLoading, getLanguage } = useLayoutV2();
  const [countries, setCountries] = useState([]);
  const [isShowEditPartnerProfilePage, setIsShowEditPartnerProfilePage] = useState(false);
  const [partner, setPartner] = useState<any>(null);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isShowSidebarEditBasic, setIsShowSidebarEditBasic] = useState(false);
  const [isShowContactInfo, setIsShowContactInfo] = useState(false);
  const [isShowPreviewPartnerProfilePage, setIsShowPreviewPartnerProfilePage] = useState(false);
  const [isShowWithdrawConsnet, setIsShowWithdrawConsent] = useState(false);

  const [cmsPartnerPage, setCMSPartnerPage] = useState({
    url: '',
    id: '',
    status: ''
  })
  const [masterData, setMasterData] = useState<any>({
    headers: [],
    footers: [],
    ctaBlocks: [],
    cmsPageTypes: [],
    cmsMasterPages: [],
    partnerPage: null
  });

  const isWISystemUser = getUserType(auth) === UserTypes.WIEmployee;
  const isTenantMatched = auth?.tenant_id === id && !isWISystemUser;
  const permissions = {
    canUpdatePartner: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_UPDATE) || isTenantMatched,
    canViewCMSPAGE: auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_VIEW),
    canUpdateCMSPAGE: auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_UPDATE),
  };

  const isFundraiser = (type: any) => type === PARTNER_TYPES.FUNDRAISER;

  const getAllData = async () => {
    try {
      setIsContentLoading(true);
      let [footers, headers, cmsPageTypes, ctaBlocks, partner] = await Promise.all([
        fetchCMSFooters(),
        fetchCMSHeaders(),
        fetchPageTypes(),
        fetchAllBlocks(),
        fetchPartnerData(id || '')
      ]);

      if (partner && isFundraiser(partner.type)) {
        navigate('/partners');
        return;
      }

      let partnerPageType = getPageTypeByCode(cmsPageTypes, PAGE_CODE.Partner);
      let masterPageType = getPageTypeByCode(cmsPageTypes, PAGE_CODE.Master);
      let querySearch: any = { conditions: { typeIds: [partnerPageType?.uuid, masterPageType?.uuid] } };
      let res = await CMsPageManagerAPI.searchAllPagesWithPagination(querySearch, { pageNo: 0, range: 10000 });
      let pages = res?.data?.records || [];
      let masterPages = pages.filter((p: any) => p.type_id === masterPageType?.uuid);
      let partnerPage = pages.find((p: any) => p.parent_id === id);
      let cmsMasterPages = fetchCMSParentPages(masterPages);
      let parentPage: any = cmsMasterPages.find((d: any) => partnerPage?.page_id === d.id);
      const partnerPageItem  = {
        ...partnerPage,
        cmsPageStatus: partnerPage ? CMS_STATUS_LIST.find((item: any) => item.code === partnerPage?.status) : {
          label: 'status.txt_disabled',
          code: 'disabled',
          color: '#3A3A3C',
          background_color: 'rgba(58, 58, 60, 0.10)'
        }
      }
      
      setCMSPartnerPage({
        ...cmsPartnerPage,
        id: partnerPage?.uuid,
        status: partnerPage?.status
      })
      setPartner({
        ...partner,
        fullName: `${partner?.person_first_name} ${partner?.person_last_name}`?.trim(),
        nameCode: `${partner?.person_first_name?.charAt(0)}${partner?.person_last_name?.charAt(0)}`?.trim(),
        statusItem: STATUS_LIST.find((item: any) => item.code === partner?.status),
        hasContactInfo: partner?.person_first_name || partner?.person_last_name || partner?.person_email || partner?.person_address
      });
      setMasterData({ headers, footers, ctaBlocks, cmsPageTypes, cmsMasterPages, partnerPage: partnerPageItem });
      if (parentPage && partnerPage) {
        setCMSPartnerPage({
          ...cmsPartnerPage,
          id: partnerPage?.uuid,
          status: partnerPage?.status,
          url: [...parentPage?.nodes?.map((c: any) => c.url), partnerPage.url].join('/')
        })
      }
    } catch (error: any) {
      setErrorProgress(error.message);
      setPartner(null);
    } finally {
      setIsContentLoading(false);
    }
  }

  const formDate = (value: any, lang: any = 'de') => {
    if (value) {
      const date = moment(new Date(value), 'MMM DD, YYYY HH:mm:ss').utc().add(1, 'hour').locale(lang).format("MMM YYYY");
      return date.replace("01:00:00", "00:00:00");
    }
  
    return '-';
  }

  const hasAddressPartner = () => {
    return partner?.person_address || partner?.person_post_code || partner?.person_city || partner?.person_country
  }

  const addressPartner = () => {
    const postCode = `${partner?.person_post_code} ${partner?.person_city}`?.trim();
    const countryPatner:any = countries.find((countryItem: any) => countryItem.ISO2 === partner?.person_country);
    return [partner?.person_address, postCode, countryPatner?.name].join(', ')
  }

  const getGoogleMapLink = (text: string) => {
    const encodedText = encodeURIComponent(text?.trim() || '');
    return `https://www.google.com/maps?q=${encodedText}`;
  }


  const fetchCmsPartnerPageURL = async (cms_page_id: any) => {
    const partnerPage = await getPartnerPage(cms_page_id);
    setCMSPartnerPage({
      ...cmsPartnerPage,
      id: cms_page_id,
      status: partnerPage?.status
    })
    const parentPage = masterData?.cmsMasterPages?.find((d: any) => partnerPage?.page_id === d.id);
    if (parentPage && partnerPage) {
      setCMSPartnerPage({
        ...cmsPartnerPage,
        id: cms_page_id,
        status: partnerPage?.status,
        url: [...parentPage?.nodes?.map((c: any) => c.url), partnerPage.url].join('/')
      })
    }
  }

  const fetchCountry = async () => {
    const countries = await getCountries();
    setCountries(countries);
  }
  const getCountries = async () => {
    try {
      const response = await CountryService.getCountries();
      if (response && response.status === 200) {
        if (response.data.data && response.data.data.length > 0) {
          return response.data.data;
        }
      }
    } catch (error) {
      console.log("getCountries: " + error);
    }
  };

  useEffect(() => {
    // Don't let the partner admin access other tenants' profiles.
    if (!isWISystemUser && !isTenantMatched) {
      navigate(HOMEPAGE_URL, { replace: true });
    }
    
    // Create new without enough mandatory fields, redirect to page partners 
    if (!id && (!partnerType || !partnerCode)) {
      navigate('/partners');
      return;
    }
    getAllData();
    fetchCountry();
  }, []);

  useEffect(() => {
    onChangeIsShowPreviewPartnerProfilePage();
  }, [cmsPartnerPage]);

  const changePartnerStatus = async (partnerValues: any, newStatus: any) => {
    try {
      if (id && partnerValues && newStatus) {
        setLoadingProgress(errorTrans('txt_loading'));
        let partnerPageId = cmsPartnerPage.id;
        const partnerData = _.cloneDeep(partnerValues);
        partnerData.status = newStatus;
        partnerData.code = partnerValues?.code?.toString()?.toUpperCase();
        partnerData.statusItem = STATUS_LIST.find((item: any) => item.code === newStatus);

        setCMSPartnerPage({
          ...cmsPartnerPage,
          id: partnerPageId
        })

        await updatePartner(id || '', partnerData);
        setPartner(partnerData);

        if (newStatus === STATUS_CODE.INACTIVE) {
          await onProcessDisablePartnerProfilePage();
        } else {
          setSuccessProgress(errorTrans('txt_update_partner_success'));
        }
      }
    } catch (error: any) {
      let errorTxt = 'txt_failed_update_partner';
      if (error.message && error.message.includes('txt_')) {
        errorTxt = error.message;
      }
      setErrorProgress(errorTrans(errorTxt));
    } finally {
    }
  };

  const isShowingEditPartnerProfilePage = () => {
    return partner?.status === STATUS_CODE.ACTIVE
          && !!cmsPartnerPage?.url && cmsPartnerPage?.status === CMS_STATUS_CODE.PUBLISHED;
  }

  const isShowingEnablePartnerProfilePage = () => {
    return (partner?.status === STATUS_CODE.ACTIVE &&
      (!cmsPartnerPage.url || cmsPartnerPage?.status !== CMS_STATUS_CODE.PUBLISHED)
    )
  }

  const isShowingDisablePartnerProfilePage = () => {
    return isShowingEditPartnerProfilePage();
  }

  const onChangeIsShowPreviewPartnerProfilePage = async () => {
    if(!cmsPartnerPage?.id){
      return false;
    }

    const isExistPage = await checkExistPage(cmsPartnerPage?.id);
    setIsShowPreviewPartnerProfilePage(isExistPage || isShowingEditPartnerProfilePage());
  }

  const onProcessEnablePartnerProfilePage = async () => {
    try {
      setLoadingProgress(errorTrans('txt_loading'));
      const partnerData = await updatePartner(partner.uuid, {cms_page_configuration: {
        is_show_description: true,
        is_show_facts: true
      }});
      const partnerPageId = await syncDataWithPartnerPage({ ...partnerData}, cmsPartnerPage?.id, masterData);
      setPartner(partnerData)
      if (!!partnerPageId) {
        await fetchCmsPartnerPageURL(partnerPageId);
        setSuccessProgress(errorTrans('txt_update_partner_success'));
        return;
      }
    } catch (e: any) {
    }
    setErrorProgress(errorTrans('txt_update_partner_failed'));
  }

  const onProcessDisablePartnerProfilePage = async () => {
    try {
      if(!cmsPartnerPage?.id){
        return;
      }

      setLoadingProgress(errorTrans('txt_loading'));
      await CMsPageManagerAPI.unpublishedPage(cmsPartnerPage.id);
      const pageData = await getPartnerPage(cmsPartnerPage.id);
      setCMSPartnerPage({
        ...cmsPartnerPage,
        id: pageData?.uuid,
        status: pageData?.status
      })
      setSuccessProgress(errorTrans('txt_update_partner_success'));
      return;
    } catch (e: any) {
    }
    setErrorProgress(errorTrans('txt_update_partner_failed'));
  }

    const callDeleteTenant = async () => {
      try {
        let res = await PartnerManagerAPI.deleteTenant(auth?.tenant_id);
        if (res && res.status === 200 ) {
          setIsShowWithdrawConsent(false);
          resetToken();
          navigate('/login');
          return;
        }
        else{
          setErrorProgress(errorTrans('txt_delete_tenant_failed'));
        }
      } catch (error) {
        setErrorProgress(errorTrans('txt_delete_tenant_failed'));
      }
  
    }

  const onProcessPreviewPartnerProfilePage = () => {
    window.open(`${env.CMS_URL}${cmsPartnerPage?.url}`, '_blank');
  }

  const onChangeStatusPartnerProfilePage = () => {
    const statusUI  = cmsPartnerPage ? CMS_STATUS_LIST.find((item: any) => item.code === cmsPartnerPage?.status) : {
      label: 'status.txt_disabled',
      code: 'disabled',
      color: '#3A3A3C',
      background_color: 'rgba(58, 58, 60, 0.10)'
    }

    return statusUI ? <WiStatus item={statusUI}></WiStatus> : <></>
  }

  const menuItems = [
    {
      label: t('txt_edit_basic_information'),
      icon: "wi-heart-handshake",
      hidden: !permissions.canUpdatePartner || !partner,
      command: () => setIsShowSidebarEditBasic(true)
    },
    {
      label: t('txt_edit_contact'),
      icon: "wi-person-circle",
      hidden: !permissions.canUpdatePartner || !partner,
      command: () => setIsShowContactInfo(true)
    },
    {
      label: t('txt_edit_partner_profile_page'),
      icon: "wi-person-profile",
      hidden: !permissions.canUpdatePartner || !isShowingEditPartnerProfilePage(),
      command: () => setIsShowEditPartnerProfilePage(true)
    },
    {
      label: t('txt_enable_partner_profile_page'),
      icon: "wi-toggle-left",
      hidden: !permissions.canUpdatePartner || !isShowingEnablePartnerProfilePage(),
      command: async () => await onProcessEnablePartnerProfilePage()
    },
    {
      label: t('txt_disable_partner_profile_page'),
      icon: "wi-toggle-right",
      className:"color-red-warning",
      hidden: !permissions.canUpdatePartner || !isShowingDisablePartnerProfilePage(),
      command: () => onProcessDisablePartnerProfilePage()
    },
    {
      label: t('txt_partner_profile_page_preview'),
      icon: "wi-screen-share",
      hidden: !permissions.canUpdatePartner || !isShowPreviewPartnerProfilePage,
      command: () => onProcessPreviewPartnerProfilePage()
    },
    {
      label: t('txt_withdraw_consent'),
      icon: "wi-protected-plus",
      hidden: (auth?.tenant_id === WI_TENANT_ID),
      command: () => setIsShowWithdrawConsent(true)
    },
    {
      label: t('txt_active'),
      icon: "wi-active",
      hidden: !isWISystemUser || !permissions.canUpdatePartner || !partner || partner?.status === STATUS_CODE.ACTIVE,
      command: () => changePartnerStatus(partner, STATUS_CODE.ACTIVE)
    },
    {
      label: t('txt_inactive'),
      icon: "wi-inactive",
      hidden: !isWISystemUser || !permissions.canUpdatePartner || !partner || partner?.status === STATUS_CODE.INACTIVE,
      command: () => changePartnerStatus(partner, STATUS_CODE.INACTIVE)
    },
    {
      label: t('txt_archive'),
      icon: "wi-archive",
      hidden: !isWISystemUser || !permissions.canUpdatePartner || !partner || partner?.status === STATUS_CODE.ARCHIVED,
      command: () => changePartnerStatus(partner, STATUS_CODE.ARCHIVED)
    },
    // {
    //   label: t('txt_edit_cms_page'),
    //   icon: "wi-edit-paint",
    //   hidden: !permissions.canUpdateCMSPAGE || !partner || partner?.type === PARTNER_TYPES.FUNDRAISER,
    //   url: `/cms-pages/${cmsPartnerPage.id}`
    // },
    // {
    //   label: t('txt_open_cms_page'),
    //   icon: "wi-open-doc",
    //   hidden: !permissions.canViewCMSPAGE || !partner || partner?.type === PARTNER_TYPES.FUNDRAISER,
    //   externalUrl:  `${env.CMS_URL}${cmsPartnerPage.url}`
    // }
  ];

  return (
    <Layout title={t('txt_user_detail')} customLayout={true} customCopyRight={true}>
      <div className="patner-details">
        <div className="partner-details-container">
          <div className="partner-details-actions pt-16 pr-24 pb-16 pl-24">
            <WiStatus item={partner?.statusItem}></WiStatus>
            <MenuDot items={menuItems}></MenuDot>
          </div>
          <div className="partner-details-content">
            <div className="content-container pt-24 pr-24 pl-24">
              <div className="partner-header">
                <div className={`partner-backgound-image ${!partner?.headline_image && 'partner-backgound-image-default'}`}>
                  {
                    partner?.headline_image &&
                    <img src={`${env.PUBLIC_UPLOAD_URL}/${partner?.headline_image}`} alt="background-image" loading="lazy" />
                  }
                </div>
              </div>
              <div className="partner-content">
                <div className="partner-content-info pt-48">
                    {
                      partner?.partner_logo && <div className="partner-logo mb-48">
                        <img src={`${env.PUBLIC_UPLOAD_URL}/${partner?.partner_logo}`} alt="logo" loading="lazy" />
                      </div>
                    }
                    <h2 className="m-0 pb-8 partner-text label">{partner?.name}</h2>
                    { 
                      partner?.slogan && partner?.slogan?.de ? <p className="m-0 pb-8 partner-text">{partner?.slogan?.de}</p> : <></> 
                    }
                    <p className="m-0 pb-8 partner-text">
                      <span className="m-0 partner-text text-des">{globalTrans(`partner_type.txt_${partner?.type?.replace('-', '_')}`)}</span>
                      {
                        partner?.category ? <span className="m-0 partner-text text-des"> - {globalTrans(`partner_category.txt_${partner?.category?.replace('-', '_')}`)}</span> : <></>
                      }
                    </p>
                    {
                      partner?.since_date ? <p className="m-0 pb-8 partner-text text-des">{t('txt_since')} {formDate(partner?.since_date, getLanguage())}</p> : <></>
                    }
                    {
                      masterData?.partnerPage?.cmsPageStatus && partner?.cms_page_configuration ? 
                      <div className="partner-prifile-page">
                        <div className="partner-text text-des pr-24">{t('txt_partner_profile_page')}</div>
                        { onChangeStatusPartnerProfilePage() }
                      </div>
                      : <></>
                    }
                </div>
                <div className="partner-contact-information">
                  {
                    partner?.hasContactInfo && 
                    <div className="partner-contact-information-container pt-48">
                      <h2 className="m-0 pb-24 partner-text label">{t('txt_contact_information')}</h2>
                      <div className="contact-info">
                        {
                          partner?.nameCode &&
                          <div className="contact-info-avatar mr-24">
                            <span className="avatar">{partner?.person_first_name?.charAt(0)}{partner?.person_last_name?.charAt(0)}</span>
                          </div>
                        }
                        <div className="contact-info-content">
                          {
                            partner?.fullName && 
                            <div className="contact-info-item pb-16">
                              <div className="icon-item mr-12"><PersonCircleSvg/></div>
                              <div className="text-item name">{partner?.fullName}</div>
                            </div>
                          }
                          {
                            partner?.person_email && 
                            <div className="contact-info-item pb-16">
                              <div className="icon-item mr-12"><MailSvg/></div>
                              <div className="text-item line">
                                <a href={`mailto:${partner?.person_email}`} target="_blank" rel="noreferrer"> {partner?.person_email} </a></div>
                            </div>
                          }
                          {
                            hasAddressPartner() && 
                            <div className="contact-info-item">
                              <div className="icon-item mr-12"><MapSvg/></div>
                              <div className="text-item line"><a href={getGoogleMapLink(addressPartner())} rel={"noreferrer"} target="_blank" >{addressPartner()}</a></div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  }
                  {
                    partner?.description?.de?.trim() ?
                    <div className="partner-description pt-48">
                      <h2 className="m-0 pb-24 partner-text label">{t('txt_description')}</h2>
                      <div className="content-text" dangerouslySetInnerHTML={{ __html: partner?.description?.de }}></div>
                    </div> : <></>
                  }
                  {
                    partner?.fact?.de?.trim() ?
                    <div className="partner-facts pt-48">
                      <h2 className="m-0 pb-24 partner-text label">{t('txt_facts')}</h2>
                      <div className="content-text" dangerouslySetInnerHTML={{ __html: partner?.fact?.de }}></div>
                    </div> : <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        visible={isShowSidebar}
        onHide={() => setIsShowSidebar(false)}
        dismissable={false}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6 className="m-0">{t('txt_change_category')}</h6>
          </div>
          <PreAddPartnerForm
            onHide={() => setIsShowSidebar(false)}
            refreshData={(updatedPartner: any) => setPartner(updatedPartner)}
            partner={partner}
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowContactInfo}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '600px' }}
        onHide={() => setIsShowContactInfo(false)}
        dismissable={false}
      >
        <div className="sidebar-content">
          <div className="headline pt-24">
            <h6>{t('txt_edit_contact')}</h6>
          </div>
          <div className="headline pb-20">
            <span style={{color: "#8E8E93"}}>
              {t('txt_edit_contact_description1')}
              <br />
              {t('txt_edit_contact_description2')}
            </span>
          </div>
          <EditContactInforForm
            fetchCallBack={(e: any) => {
              setIsShowContactInfo(false); 
              getAllData()
            }}
            countries={countries}
            partner={partner}
            onHide={() => setIsShowContactInfo(false)}
          />
        </div>
      </Sidebar>
      <Sidebar
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '600px' }}
        visible={isShowSidebarEditBasic}
        onHide={() => setIsShowSidebarEditBasic(false)}
        dismissable={false}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6 className="m-0">{t('txt_edit_basic_information')}</h6>
          </div>
          <EditBasicInformation
            onHide={() => setIsShowSidebarEditBasic(false)}
            partner={partner}
            partnerCode={partnerCode}
            partnerType={partnerType}
            partnerCategory={partnerCategory}
            id={id}
            cmsPartnerPageId={cmsPartnerPage.id}
            masterData={masterData}
            callFeedBack={getAllData}
            />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowEditPartnerProfilePage}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '600px' }}
        onHide={() => setIsShowEditPartnerProfilePage(false)}
        dismissable={false}
      >
        <div className="sidebar-content">
          <div className="headline pt-24">
            <h6>{t('txt_edit_partner_profile_page')}</h6>
          </div>
          <div className="headline pb-20">
            <span style={{color: "#8E8E93"}}>
              {t('txt_edit_profile_description1')}
              <br />
              {t('txt_edit_profile_description2')}
            </span>
          </div>
          <EditPartnerProfilePageForm
            fetchCallBack={(e: any) => {
                setIsShowEditPartnerProfilePage(false);
                getAllData();
                fetchCmsPartnerPageURL(e.cms_page_id);
              }
            }
            partner={partner}
            cmsPartnerPage={cmsPartnerPage}
            masterData={masterData}
            onHide={() => setIsShowEditPartnerProfilePage(false)}
          />
        </div>
      </Sidebar>
      <WithdrawConsent
        visible={isShowWithdrawConsnet}
        onHide={() => setIsShowWithdrawConsent(false)}
        onConfirm={() => callDeleteTenant()}
      />
    </Layout>
  );
};

export default PartnerDetails;
