import { useEffect, useRef } from "react";
import React from "react";
import "./edit-basic-information.scss";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useLayoutV2 } from "../../../../context/LayoutProvider";
import _ from 'lodash';
import { buildPartnerSchema, initializePartnersValues, syncDataWithPartnerPage, syncPartnerData } from "../../partner-business";
import { WIFormDropdownV2, WIFormImageSelectorV2, WIFormInputTextV2, WIFormObserver } from "../../../../components_v2/common/form-fields";
import { RichTextEditorV2 } from "../create-partner-form/custom-editor-component";
import { PARTNER_CATEGORY_LIST, PARTNER_TYPE_LIST, PERMISSIONS_V2 } from "../../../../components_v2/utils/utils";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { getUserType } from "../../../../utils/logic";
import { UserTypes } from "../../../../utils/utils";
import useAuth from "../../../../context/useAuth";
import WithPreventNavigation from "../../../common/preventNavigation";
import { useBeforeUnload } from "../../../common/useBeforeUnload";

const EditBasicInformation = (props: any) => {
  const { auth } = useAuth();
  const { setLoadingProgress, setSuccessProgress, setErrorProgress, actionPopupSaveChange } = useLayoutV2();
  const { onHide, partner, partnerCode, partnerType, partnerCategory, id, callFeedBack, cmsPartnerPageId, masterData,
    setBlockPopupVisible, shouldBlock, setShouldBlock, setConfirmedNavigation
   } = props;

  const formikRef = useRef<any>(null);
  const { t } = useTranslation('language', { keyPrefix: 'partner_details' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t: langTrans } = useTranslation('language');

  const isWISystemUser = getUserType(auth) === UserTypes.WIEmployee;
  const isTenantMatched = auth?.tenant_id === id && !isWISystemUser;
  const permissions = {
    canUpdatePartner: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_UPDATE) || isTenantMatched,
  };

  const hasSaveDataForm = () => {
    const { dirty } = formikRef?.current;
    if(formikRef && formikRef.current && dirty) {
      actionPopupSaveChange({
        visible: true,
        onCancel: () => onHide(),
        onSaveChange: () => onUpdateBasicPartner()
      })
    } else {
      onHide();
    }
  }

  const onUpdateBasicPartner = async () => {
    setBlockPopupVisible(false);
    if (formikRef && formikRef.current) {
      formikRef.current.validateForm();
      ['name', 'partner_logo', 'type', 'category'].forEach(f => {
        formikRef.current.setFieldTouched(f, true);
      });
    }
    const { values, dirty, isValid } = formikRef?.current;

    if (formikRef && dirty && isValid) {
      try {
        setLoadingProgress(errorTrans('txt_loading'));

        // @ts-ignore: Object is possibly 'null'.
        const partnerValues = _.cloneDeep(values);
        partnerValues.code = partner ? partner?.code?.toString()?.toUpperCase() : partnerCode;
        const partnerData = await syncPartnerData(id, partnerValues);
        let partnerPageId = await syncDataWithPartnerPage(partnerData, cmsPartnerPageId, masterData);
        if (partnerData) {
          callFeedBack();
          setSuccessProgress(errorTrans('txt_update_partner_success'));
          onHide();

          // Remove block 
          setShouldBlock(false);
          setConfirmedNavigation(true);
        } else {
          throw new Error('txt_failed_update_partner');
        }
      } catch (error: any) {
        let errorTxt = 'txt_failed_update_partner';
        if (error.message && error.message.includes('txt_')) {
          errorTxt = error.message;
        }
        setErrorProgress(errorTrans(errorTxt));
      } finally {
      }
    }
  };

  const commonItemTemplate = (option: any) => {
    return (<div>{option?.label ? langTrans(option?.label) : ''} </div>);
  };

  useBeforeUnload((event: any) => {
    if (shouldBlock) {
      event.preventDefault();
    }
  });

  return (
    <>
      <div className="edit-basic-information">
        <div className="edit-basic-information-container">
          <Formik
            innerRef={formikRef}
            initialValues={initializePartnersValues(partner, partnerCode, partnerType, partnerCategory)}
            validationSchema={buildPartnerSchema(errorTrans)}
            onSubmit={(values, { setSubmitting }) => {
              //console.log("values >>>", values);
            }}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              isValid,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleChange,
            }) => (
              <>
                <WIFormObserver setShouldBlock={setShouldBlock} />
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row pb-24">
                    <div className="col-md-12">
                      {t('txt_edit_basic_information_title')}
                    </div>
                  </div>
                  <div className="row pb-24">
                    <div className="col-md-12">
                      <div className="element-form">
                        <WIFormInputTextV2
                          title={t('txt_name')}
                          name="name"
                          isRequired={true}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pb-24">
                    <div className="col-md-6">
                      <div className="element-form partner-logo-img">
                        <WIFormImageSelectorV2
                          title={t('txt_logo')}
                          name="partner_logo"
                          className="partner-logo-img"
                          isRequired={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pb-24">
                    <div className="col-md-6">
                      <div className="element-form">
                        {
                          isWISystemUser ? <WIFormDropdownV2
                            name='type'
                            title={t('txt_category')}
                            isRequired={true}
                            optionLabel="label"
                            optionValue="code"
                            appendTo="self"
                            valueTemplate={commonItemTemplate}
                            itemTemplate={commonItemTemplate}
                            options={PARTNER_TYPE_LIST}
                          /> : <></>
                        }
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="element-form">
                        {
                          isWISystemUser ? <WIFormDropdownV2
                            name='category'
                            title={t('txt_subcategory')}
                            isRequired={true}
                            optionLabel="label"
                            optionValue="code"
                            appendTo="self"
                            valueTemplate={commonItemTemplate}
                            itemTemplate={commonItemTemplate}
                            options={PARTNER_CATEGORY_LIST}
                          /> : <></>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="row pb-24">
                    <div className="col-md-6">
                      <div className="element-form">
                        <WIFormInputTextV2
                          title={t('txt_slogan')}
                          name="slogan.de"
                          maxLength={100}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label mb-16">
                          {t("txt_become_partner_since")}
                        </label>
                        <div className="calendar-item">
                          <Calendar
                            id="basic"
                            name="since_date"
                            className="wi-calendar-v2"
                            value={values.since_date}
                            onBlur={handleBlur}
                            dateFormat="mm.yy"
                            iconPos="right"
                            view="month"
                            icon="fa-solid fa-calendar-days"
                            showIcon={true}
                            onChange={(item) => {
                              setFieldValue("since_date", item.value, false);
                            }}
                            appendTo="self"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pb-24">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label mb-16">
                          {t('txt_description')}
                        </label>
                        <RichTextEditorV2
                          name="description.de"
                          isMultipleLine={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pb-24">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label mb-16">
                          {t('txt_fact')}
                        </label>
                        <RichTextEditorV2
                          name="fact.de"
                          isMultipleLine={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pb-24">
                    <div className="col-md-12">
                      <div className="element-form">
                        <WIFormImageSelectorV2
                          title={t('txt_cover_picture')}
                          name="headline_image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pb-24">
                    <div className="col-md-12">
                      <div className="element-form">
                        <WIFormImageSelectorV2
                          title={t('txt_mobile_cover_picture')}
                          name="mobile_headline_image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mb-24 gap-24">
                    <Button
                      className="wi-danger-button-v2 h48 flex-1"
                      type="submit"
                      label={t('txt_cancel')}
                      onClick={() => hasSaveDataForm()}
                    ></Button>
                    <Button
                      className={`wi-primary-button-v2 h48 flex-1`}
                      type="submit"
                      label={t('txt_save')}
                      onClick={() => onUpdateBasicPartner()}
                      disabled={!permissions?.canUpdatePartner}
                    ></Button>
                  </div>
                </div>
              </form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default WithPreventNavigation(EditBasicInformation);
