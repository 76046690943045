import * as React from "react";
import { useState, useEffect } from "react";
import "./header-editor.scss";
import "./mega-menu.scss";
import HeaderItem from "../header-item/header-item";
import { TabPanel, TabView } from "primereact/tabview";
import HeaderItemEditor from "../header-item-editor/header-item-editor";
import _ from "lodash";
import { CMsPageManagerAPI } from "../../../../../services";
import { getInternalURLPages } from "../../../../pages/builder-page.common";
import { WIButton } from "../../../../common";
import { v4 as uuidv4 } from "uuid";
import WithPreventNavigation from "../../../../common/preventNavigation";
import WIBlockDialog from "../../../../pages/components/block-modal/block-modal";
import { useBeforeUnload } from "../../../../common/useBeforeUnload";
import HeaderButtonConfigDialog from "../header-button-config/header-button-config";
import MenuButton from "../../../../common/menu-button/menu-button";
import useAuth from "../../../../../context/useAuth";
import { PERMISSIONS_V2 } from "../../../../../components_v2/utils/utils";

const HeaderEditor = (props: any) => {
  const { blockPopupVisible, setBlockPopupVisible, shouldBlock, setShouldBlock, setConfirmedNavigation, confirmNavigation, header, onUpdateHeaders } = props;
  const [activeItem, setActiveItem] = useState<any>(null);
  const [headerIndex, setHeaderIndex] = useState<any>(0);
  const [visible, setVisible] = useState<any>(false);
  const [configButonVisible, setConfigButonVisible] = useState<any>(false);
  const [globalContent, setGlobalContent] = useState<any>([]);
  const [data, setData] = useState<any>(header.parameters?.menuItems || []);
  const [buttonData, setButtonData] = useState<any>(_.pick(header.parameters, ['urlName', 'buttonUrl', 'blank_mode']) || {});
  const { auth } = useAuth();

  const permissions = {
    canUpdateHeader: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_UPDATE),
  };
  const selectHeaderItem = (item: any) => {
    if (!activeItem || activeItem.uuid !== item.uuid) {
      setActiveItem(item);
    } else {
      setActiveItem(null);
    }
  }

  const applyChanges = (item: any) => {
    const newData = _.cloneDeep(data);
    for (let i = 0; i < newData.length; i++) {
      for (let j = 0; j < newData[i].content.length; j++) {
        if (newData[i].content[j] && newData[i].content[j].uuid === item.uuid) {
          newData[i].content[j] = item;
          break;
        }
      }
      newData[i].content = newData[i].content.filter((d: any) => !!d);
    }
    setActiveItem(item);
    setShouldBlock(true);
    setData(newData);
  }

  const deleteHeaderItem = (index: number, headerTab: any, item: any) => {
    headerTab.content = headerTab.content.filter((c: any) => c.uuid !== item.uuid);
    data[index] = headerTab;

    setShouldBlock(true);
    setData(_.cloneDeep(data));
  }

  const onMove = (index: number, headerTab: any, item: any, isUp: boolean) => {
    const allIndexs = headerTab.content.map((e: any, i: number) => e.columnNo === item.columnNo ? i : -1)
      .filter((index: number) => index !== -1);
    const itemIndex = headerTab.content.findIndex((c: any) => c.uuid === item.uuid);
    const noIndex = allIndexs.findIndex((i: number) => i === itemIndex);
    const temp = headerTab.content[itemIndex];
    const nextIndex = Math.min(Math.max(noIndex + (isUp ? -1 : 1), 0), allIndexs.length - 1);
    headerTab.content[itemIndex] = headerTab.content[allIndexs[nextIndex]];
    headerTab.content[allIndexs[nextIndex]] = temp;

    data[index] = headerTab;

    setShouldBlock(true);
    setData(_.cloneDeep(data));
  }

  const duplicateHeaderItem = (index: number, headerTab: any, item: any) => {
    const itemIndex = headerTab.content.findIndex((c: any) => c.uuid === item.uuid);
    const newItem = _.cloneDeep(headerTab.content[itemIndex]);
    newItem.uuid = uuidv4();
    newItem.disabledDelete = false;
    if (newItem.items) {
      newItem.items.forEach((d: any) => { 
        d.uuid = uuidv4();
        d.disabledDelete = false;
        d.disabled = false;
        d.onClick = null;
      });
    }
    headerTab.content.splice(itemIndex, 0, newItem);
    data[index] = headerTab;

    setShouldBlock(true);
    setData(_.cloneDeep(data));
  }

  const getSearchContent = async () => {
    let pagesRes = await CMsPageManagerAPI.getAllPages();
    let allPages = pagesRes?.data?.records;

    let internalUrls = getInternalURLPages(allPages);
    let pageIds = allPages.map((m: any) => m.uuid);
    let pageUrls = internalUrls.filter(f => pageIds.includes(f.id));
    setGlobalContent(pageUrls || []);
  };

  const saveChanges = async () => {
    setBlockPopupVisible(false);
    setActiveItem(null);
    onUpdateHeaders(data, buttonData);
    setShouldBlock(false);
    setConfirmedNavigation(true);
  }

  const applyButtonChanges = async (buttonData: any) => {
    setShouldBlock(true);
    setButtonData(buttonData);
  }

  useBeforeUnload((event: any) => {
    if (shouldBlock) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    getSearchContent();
  }, []);

  const buildOptions = (tabIndex: number, item: any, colIndex: number) => {
    const specialOptions = [{
      label: "Page",
      icon: "fa-solid fa-pager",
      command: (e: any) => {
        item.content.push({
          uuid: uuidv4(),
          label: 'Untitled', 
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
          image: 'news-thumbnail.jpg',
          columnNo: colIndex,
          type: 'page',
          link: {}
        });
        data[tabIndex] = item;
        setData(_.cloneDeep(data));
      }
    }];
    const options = [
      {
        label: "List Item",
        icon: "fa-solid fa-list",
        command: (e: any) => {
          item.content.push({
            uuid: uuidv4(),
            label: 'Category Name',
            columnNo: colIndex,
            type: 'list-sub-items',
            items: [
              { uuid: uuidv4(), label: 'Item 1', link: {} },
              { uuid: uuidv4(), label: 'Item 2', link: {} },
            ]
          });
          data[tabIndex] = item;
          setData(_.cloneDeep(data));
        }
      },
      {
        label: "List Item With Description",
        icon: "fa-solid fa-align-left",
        command: (e: any) => {
          item.content.push({
            uuid: uuidv4(),
            columnNo: colIndex,
            type: 'list-sub-items-description',
            items: [
              { uuid: uuidv4(), label: 'Item 1', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', link: {} }
            ]
          });
          data[tabIndex] = item;
          setData(_.cloneDeep(data));
        }
      }
    ];

    return colIndex !== 4 ? options : specialOptions;
  }

  return <div>
    <div className="header-page-wrapper mb-3 mt-3">
      <div className="header-title">Header Navigation Setting</div>
      <div className="header-operation-panel">
        <WIButton type="submit"
          className="btn-edit-config ml-3"
          label="Configure Button"
          onClick={() => setConfigButonVisible(true)}
          hidden={!permissions.canUpdateHeader}
        />
        <WIButton type="submit"
          className="btn-edit-config ml-3"
          label="Save Changes"
          hidden={!permissions.canUpdateHeader}
          onClick={() => {
            setActiveItem(null);
            onUpdateHeaders(data, buttonData);
            setShouldBlock(false);
          }}
        />
      </div>
    </div>
    <HeaderButtonConfigDialog
      visible={configButonVisible}
      setVisible={setConfigButonVisible}
      globalContent={globalContent}
      data={buttonData}
      setData={applyButtonChanges}
    />
    <div className="header-editor-wrapper">
      <TabView activeIndex={headerIndex} onTabChange={(event: any) => setHeaderIndex(event.index)}>
        {
          data?.map((item: any, index: number) => {
            return <TabPanel header={item.label} contentClassName={'header-tab-content'} key={`header-${index}`}>
              <div className={`header-wrapper ${index === 0 ? 'header-wrapper-max-width' : ''}`}>
                {
                  [1, 2, 3, 4].map((col: number) => {
                    const isColumnSelected = activeItem?.columnNo === col;
                    return <div className={`header-column ${isColumnSelected ? 'header-column-active' : ''}`} key={`header-${index}-${col}`}>
                      {
                        item.content.filter((m: any) => m.columnNo === col).map((m: any) =>
                          <HeaderItem key={`header-item-${m.uuid}`}
                            data={m}
                            isActive={activeItem?.uuid === m.uuid}
                            onClick={() => selectHeaderItem(m)}
                            onEdit={(e: any) => {
                              e.stopPropagation();
                              setVisible(true);
                            }}
                            onDelete={() => deleteHeaderItem(index, item, m)}
                            onMoveUp={() => onMove(index, item, m, true)}
                            onMoveDown={() => onMove(index, item, m, false)}
                            onDuplicate={() => duplicateHeaderItem(index, item, m)}
                          ></HeaderItem>
                        )
                      }
                      <MenuButton
                        label=""
                        icon="fa-plus"
                        items={buildOptions(index, item, col)}
                      ></MenuButton>
                    </div>
                  })
                }
              </div>
            </TabPanel>
          })
        }
      </TabView>
    </div>
    <HeaderItemEditor
      visible={!!(visible && activeItem)}
      setVisible={setVisible}
      globalContent={globalContent}
      data={activeItem}
      setData={applyChanges} />

    <WIBlockDialog
      visible={blockPopupVisible}
      onStay={() => setBlockPopupVisible(false)}
      onLeave={confirmNavigation}
      onSaveChanges={saveChanges}
      message={
        <React.Fragment>
          <div className="mb-3">
            You have modified this item.
            <br />
            You can save your changes, discard your changes, or cancel to continue editing.
          </div>
        </React.Fragment>
      }
    >
    </WIBlockDialog>
  </div>
};

export default WithPreventNavigation(HeaderEditor);
