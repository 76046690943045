import React, { useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { useIdleTimer } from 'react-idle-timer';
import { AuthProvider } from './context/authProvider';
import AuthProtectedRoute from './context/AuthProtectedRoute';
import { AdminRoutes, CommonRoutes } from './routes/AdminRoutes';
import { USER_PARTIES } from './components_v2/utils/utils';
import ToolkitManager from './components/toolkit/toolkit';
import AuthPartyPage from './context/AuthPartyPage';
import LoginPage from './components/login/login';
import ConfirmNewPasswordPage from './components/login/confirm-new-password';
import PageComponent from './components/common/page';
import NotFoundPage from './components/404/404-component';
import ImportOldDonationsPage from './components/import-old-data/import-old-donations-page';
import { LayoutProvider } from './context/LayoutProvider';
import Homepage from './components/homepage';
import PowerBIReport from './components/power-bi-report/power-bi-report';
import ForgotPasswordPage from './components/login/forgot-password';
import AcceptTermPage from './components/login/accept-term/accept-term';

const Dashboard = () => {
  return (<Outlet />);
}

function App() {
  const timeout = 1000 * 5 * 60;
  const promptTimeout = 1000 * 30;

  const onActive = () => {
    // DrupalAPI.refreshJWT(); // TODO: refresh Token
  }

  const onIdle = () => {
    // console.log('idle', getLastActiveTime());
  }

  const { getRemainingTime, getLastActiveTime, isPrompted, activate } = useIdleTimer({
    timeout,
    onActive,
    onIdle,
    // crossTab: true,
    // syncTimers: 200,
    name: 'activity-timer'
  });

  activate();

  useEffect(() => {
    console.log(getRemainingTime())
  }, [getRemainingTime])

  return (
    <LayoutProvider>
      <AuthProvider>
        <Router>
          <div className="App">
            <div className="app-container">
              <Routes>
                <Route
                  element={<AuthProtectedRoute><Dashboard /></AuthProtectedRoute>}
                  path="/"
                  key="DashboardPage"
                >
                  <Route element={<AuthPartyPage allowedParties={[USER_PARTIES.WILDERNESS_INTERNATIONAL]}><Dashboard /></AuthPartyPage>}>
                    <Route path="/import-old-donations" element={<PageComponent component={ImportOldDonationsPage} title="Import Old Data" />} />
                    <Route path="/dev-toolkit" element={<PageComponent component={ToolkitManager} title="Dev Toolkit Manager" />} />
                    {
                      AdminRoutes.map(r => (
                        <Route path={r.path} element={r.element} />
                      ))
                    }
                  </Route>
                  <Route element={<AuthPartyPage allowedParties={[USER_PARTIES.WILDERNESS_INTERNATIONAL, USER_PARTIES.PARTNER]}><Dashboard /></AuthPartyPage>}>
                    <Route path="/dashboard" element={<PageComponent component={PowerBIReport} title="Home" />} />
                    <Route path="/" element={<PageComponent component={Homepage} title="Home" />} />
                    {/* <Route path="/" element={<PageComponent component={ImportHistory} title="Dashboard" />} /> */}
                    {
                      CommonRoutes.map(r => (
                        <Route path={r.path} element={r.element} />
                      ))
                    }
                  </Route>
                </Route>
                <Route path="*" element={<PageComponent component={NotFoundPage} title="Not Found" />} />
                <Route path="/login" element={<PageComponent component={LoginPage} title="Login" />} />
                <Route path="/terms-and-conditions" element={<PageComponent component={AcceptTermPage} title="Terms and Conditions" />} />
                <Route path="/confirm-new-password" element={<PageComponent component={ConfirmNewPasswordPage} title="Confirm New Password" />} />
                <Route path="/forgot-password" element={<PageComponent component={ForgotPasswordPage} title="Forgot Password" />} />
              </Routes>
            </div>
          </div>
        </Router>
      </AuthProvider>
    </LayoutProvider>
  );
}

library.add(fas);
export default App;

{/* 
<Route path="/utilities/certificate" element={<PageComponent component={CertificateManager} title="Certificate Manager" />} />
<Route path="/global-contents/partner-types" element={<PageComponent component={PartnerTypeManager} title="Partner Type Manager"/>} />
<Route path="/donations/new" element={<PageComponent component={AddDonationComponent} title="Add Donation" />} />
<Route path="/import-old-donations" element={<PageComponent component={ImportOldDonationsPage} title="Import Old Data" />} />
<Route path="/import-recurring-donations" element={<PageComponent component={ImportRecurringDonations} title="Import Recurring Donations" />} />
<Route path="/import-cms-pages" element={<PageComponent component={ImportCMSPages} title="Import CMS Pages" />} />
<Route path="/import-campaigns" element={<PageComponent component={ImportCampaignsPage} title="Import Campaigns" />} />
<Route path="/geo-coordinate-imports" element={<PageComponent component={GeoImportHistory} title="Import Geo Coordinate" />} /> 
<Route path="/geo-coordinate-imports/new" element={<PageComponent component={ImportGeoForm} title="Geo Coordinate Detail" />} />
<Route path="/geo-coordinate-imports/:id" element={<PageComponent component={GeoImportDetail} title="Geo Coordinate Detail" />} />
<Route path="/recurring-donations/mailings" element={<PageComponent component={MailingsManager} title="Mailings" />} />
<Route path="/recurring-donations/mailings/:id" element={<PageComponent component={MailingDetail} title="Mailing Details" />} />
*/}
