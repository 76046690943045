import { Button } from "primereact/button";
import * as React from "react";
import './action-form.scss';
import { useTranslation } from "react-i18next";
import { ReactComponent as SaveSvg } from "../../../../assets/images/icons/save.svg";
import { ReactComponent as BackSvg } from '../../../../assets/images/icons/arrow-narrow-left.svg';
import MenuDot from "../../../../components_v2/common/menu-dot/menu-dot";

const ActionFormComponent = (props: any) => {
  const { data, isEdit, actionBack, onSubmit, menuItems = [] } = props;
  const { t } = useTranslation('language', { keyPrefix: 'area_details' });

  return (
    <div className="action-form-land pt-16 pr-24 pb-16 pl-24">
      <div className="d-flex flex-row justify-content-between w-100">
        <div className="d-flex flex-row land-code">
          {
            data?.isShowCreate ? 
            <span>{t('txt_new')}</span>
            :
            <>
            <span>{t('txt_area_code')}</span><strong className="code pl-4">{data?.selectedLand?.code || ""}</strong>
            </>
          }
        </div>
        <div className="d-flex flex-row gap-16">
          {!data?.isShowCreate ? !isEdit && <MenuDot items={menuItems} /> : <Button
            className="wi-primary-button-v2"
            label={t('txt_create')}
            onClick={(e: any) => onSubmit()}
            >
            <SaveSvg className="icon-svg"/>
          </Button>}
          <Button
            className="wi-danger-button-v2"
            label={t('txt_back')}
            onClick={(e: any) => actionBack()}
            hidden={!isEdit}>
            <BackSvg className="icon-svg"/>
          </Button>
          <Button
            className="wi-primary-button-v2"
            label={t('txt_save')}
            onClick={(e: any) => onSubmit()}
            hidden={!isEdit}>
            <SaveSvg className="icon-svg"/>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ActionFormComponent;